import { PollQuestion } from "@/types/poll";
import { Topic } from "@/types/topic";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { FormattedMessage } from "react-intl";

interface CardFrontInterface {
    flip: (e: boolean) => void;
    question: Pick<PollQuestion, "question">;
    topics: Topic[];
    coverImage: string;
    voteCount: number;
    closingAt?: string;
}

export default function CardFront({
    flip,
    question,
    topics,
    coverImage,
    voteCount,
    closingAt,
}: CardFrontInterface) {
    dayjs.extend(duration);
    dayjs.extend(relativeTime);

    return (
        <motion.button
            className="rounded-xl border text-left flex flex-col h-full min-h-[32rem] relative bg-white w-full overflow-auto"
            onClick={() => flip(true)}
        >
            <div className="aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-t-xl bg-gray-100">
                <img src={coverImage} className="object-cover" alt="" />
            </div>
            <div className="p-10 max-w-full flex-grow flex flex-col">
                <div className="flex gap-4 max-w-full">
                    {topics.map((item, index) => (
                        <span
                            className={`bg-voice-xlight backdrop-blur-xl text-red-500 px-3 py-1 rounded-lg text-sm text-nowrap overflow-hidden ${index === 0 ? "flex-shrink-0" : "flex-shrink overflow-ellipsis "}`}
                            key={item.label}
                        >
                            {item.label}
                        </span>
                    ))}
                </div>

                <h3 className="text-2xl font-medium pt-4 pb-2 flex-grow">
                    {question.question}
                </h3>
                <span className="text-sm text-gray-400">
                    <FormattedMessage
                        id=":count votes"
                        values={{ count: voteCount }}
                    />
                    {closingAt && (
                        <FormattedMessage
                            id=" · :duration left"
                            values={{
                                duration: dayjs
                                    .duration(dayjs(closingAt).diff(dayjs()))
                                    .humanize(),
                            }}
                        />
                    )}
                </span>
            </div>
        </motion.button>
    );
}
